import { authService, customerSaleor } from '../services';

export function withAuth(gssp) {
  return async (context) => {
    try {
      const token = authService.getToken(context.req, context.res);
      const refresh = authService.getRefresh(context.req, context.res);

      if (!token || !refresh) {
        return {
          redirect: {
            destination: '/connexion',
            permanent: false,
          },
        };
      }

      if (token && refresh) {
        const dataToken = await customerSaleor.verifyToken(token);
        const dataRefresh = await customerSaleor.verifyToken(refresh);
        if (!dataToken || (dataToken && !dataToken.isValid) || !dataRefresh || (dataRefresh && !dataRefresh.isValid) ) {
          return {
            redirect: {
              destination: '/connexion',
              permanent: false,
            },
          };
        }
      }

      const gsspData = await gssp(context);
      return {
        ...gsspData
      };

    } catch (err) {
      return {
        redirect: {
          destination: '/connexion',
          permanent: false,
        },
      };
    }
  };
}
