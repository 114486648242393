
import { useEffect, useState } from 'react';
import { useAppContext } from '../context';

export const withAlert = (gssp) => async (context) => {
  const gsspData = await gssp(context);
  try {
    let queryAlert = context?.query?.alert ?? null
    let alert = null
    switch(queryAlert) {
      case 'email':
        alert = {
          status: 200,
          msg: "Votre email a bien été changé\nMerci de vous reconnectez dès à présent"
        }
        break;
      case 'login':
        alert = {
          status: 401,
          msg: "Vous devez vous connecter pour accéder à cette page"
        }
        break;
      case 'change-mdp':
        alert = {
          status: 200,
          msg: "Votre compte a bien été mis à jour\nConnectez-vous dès à présent avec votre nouveau mot de passe"
        }
        break;
    }
    return {
      ...gsspData,
      props: {
        ...gsspData.props,
        alert
      },
    };
  } catch (error) {
    return {
      ...gsspData,
    };
  }
}

export const WithAlert = (Component) => (props) => {
  const { addAlert } = useAppContext();
  const [ mounted, setMounted ] = useState(false)

  useEffect(() => {
    if (props.alert && !mounted) {
      addAlert(props.alert)
      setMounted(true)
    }
  }, [])

  return <Component {...props} />
}

export default { withAlert, WithAlert }