import { setGraphContext } from '../../apollo-client';
import { authService, customerSaleor, individuPresseService } from '../services';

export function withEmailToken(gssp) {
  return async (context) => {
    try {
      const token = authService.getToken(context.req, context.res);
      const refresh = authService.getRefresh(context.req, context.res);

      if (!token || !refresh) {
        return {
          redirect: {
            destination: '/connexion',
            permanent: false,
          },
        };
      }

      if (refresh) {
        const data = await customerSaleor.verifyToken(refresh);
        if (!data || (data && !data.isValid)) {
          return {
            redirect: {
              destination: '/connexion',
              permanent: false,
            },
          };
        }
      }

      if (!context || !context.query || !context.query.token) {
        return {
          redirect: {
            destination: '/',
            permanent: false,
          },
        };
      }

      setGraphContext(context.req, context.res)
      const { user } = await customerSaleor.confirmEmailChange(context.query.token)
      const indivNum = user?.metadata?.find(element => element.key === "INDIV_NUM")?.value?.trim();
      await individuPresseService.confirmEmailChange(indivNum, user.email)

      return {
        redirect: {
          destination: '/?alert=email',
          permanent: false,
        },
      };

    } catch (err) {
      return {
        redirect: {
          destination: '/connexion',
        },
      };
    }
  };
}
