import { authService, customerSaleor } from '../services';

export function withoutAuth(gssp) {
  
  return async (context) => {
    try {
      const token = authService.getToken(context.req, context.res);
      const refresh = authService.getRefresh(context.req, context.res);

      if (token && refresh) {
        const dataToken = await customerSaleor.verifyToken(token);
        const dataRefresh = await customerSaleor.verifyToken(refresh);
        if (dataToken && dataToken.isValid && dataRefresh && dataRefresh.isValid) {
          return {
            redirect: {
              destination: '/mon-espace',
              permanent: false,
            },
          };
        }
      }

      const gsspData = await gssp(context);
      return {
        ...gsspData
      };

    } catch (err) {
      const gsspData = await gssp(context);
      return {
        ...gsspData,
      };
    }
  };
}

