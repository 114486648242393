import { useState } from 'react';
import { SWRConfig } from 'swr';
import { GiCheckMark } from 'react-icons/gi'

import serverStore from '../lib/utils/serverStore';

import {
  settingService,
  issueSaleor,
  leplusMagazineService
} from '../lib/services';
import { axiosInstance, getIssueNumber } from '../lib/utils';

import { withAlert, WithAlert } from '../lib/hocs';
import Image from 'next/image';
import { Offer, CarrouselIssue, LastIssue, LePlus, Button, Popup } from '../lib/components';
import { setGraphContext } from '../apollo-client';
import Link from 'next/link';
import Head from 'next/head';

import getConfig from 'next/config';
const { publicRuntimeConfig } = getConfig();

function Home({ fallback, issues, lastIssue, lastLePlus }) {
  const [popup, setPopup] = useState(false)

  return (
    <div className="py-2 overflow-x-clip">
      <SWRConfig value={{ fallback }}>
        <Head>
          <meta name="google-site-verification" content={publicRuntimeConfig.GOOGLE_SEARCH} />
        </Head>
        <section className="mx-5 lg:mx-20 xl:mx-40">
          <div className="flex justify-between">
            <div className="w-[40%] lg:w-1/2 h-full hidden md:block">
              <div className="relative w-1/2 pt-[5%]">
                {lastIssue &&
                <Link className="ml-[10%] block cover-month border border-black w-full" href={`/librairie/${getIssueNumber(lastIssue)}`}>
                  <Image
                    src={lastIssue.thumbnail.url}
                    alt={lastIssue.thumbnail.alt}
                    width={1200}
                    height={400}
                    style={{
                      maxWidth: '100%',
                      height: 'auto',
                    }}
                  />
                </Link>
                }
                <div className="absolute top-0 w-full aspect-square bg-orange z-[-1]"></div>
                <span className="absolute top-0 right-[-70%] text-[40vw] text-pink z-[-1] leading-[0.5]">&</span>
              </div>
            </div>
            {/* <div className="cover-month bg-pink w-[500px] h-[679px] relative mt-5 ml-5"></div> */}
            <div className="w-full md:w-[60%] lg:w-1/2">
              <h1 className="text-2xl xs:text-4xl md:text-5xl 2xl:text-7xl font-bold text-pink leading-snug whitespace-nowrap">
                La revue pédagogique<br />de l'Office Central<br />de la Coopération<br />à l'École
              </h1>
              <p className="text-2xl 2xl:text-3xl mt-10 mb-12">L'outil de réflexion et d'information au service des enseignants et des formateurs.</p>
              {/* <Button
                link={true}
                action="/abonnement"
                text="Je m'abonne"
                className="xs:text-right xs:ml-auto px-10 py-4 text-lg xl:text-2xl"
              /> */}
            </div>
          </div>
        </section>

        <section className="w-full bg-green-bg py-14 my-14">
          <div className='flex flex-col xs:grid grid-cols-12 mx-5 md:mx-10 lg:mx-20 xl:ml-30 2xl:ml-40 gap-8'>
            <div className='col-span-12 lg:col-span-6 my-auto'>
              <h2 className="mb-7">
                Outil de réflexion et d'information au service des enseignants et des formateurs, <b>Animation
                  & Éducation</b> propose tous les <span className="whitespace-nowrap">2 mois :</span>
              </h2>
              <ul>
                <li className="flex justify-between items-center mb-4">
                  <div className="mr-10 hidden xs:block">
                    <GiCheckMark className="text-5xl" />
                  </div>
                  <p className="text-xl">
                    <b>une vingtaine de pages « Dossier »</b> destinées à approfondir une thématique disciplinaire
                    ou transversale, à nourrir la réflexion et à apporter des ressources théorico-pratiques aux acteurs
                    et actrices de terrain. Loin des canons médiatiques habituels, les sujets abordés tels l'éthique à
                    l'école, l'apprentissage par les pairs, le statut de l'erreur, la classe dehors, la pratique philosophique
                    avec les enfants... mettent en exergue les qualités des pédagogies différentes et des actions d'enseignantes et d'enseignants
                    qui questionnent leur pratique.
                  </p>
                </li>
                <li className="flex justify-between items-center mt-4">
                  <div className="mr-10 hidden xs:block">
                    <GiCheckMark className="text-5xl" />
                  </div>
                  <p className="text-xl">
                    <b>une vingtaine de pages « Hors-dossier »</b> avec des rubriques diversifiées (4 questions à, Suivi de projet, Arts et
                    culture, Images et Pages...) permettant d'aborder de façon régulière d'autres sujets en lien avec l'actualité du système
                    éducatif comme les nouvelles technologies de l'information et de la communication, l'édition pédagogique ou jeunesse,
                    l'éducation aux médias et à l'information...
                  </p>
                </li>
              </ul>
              <div className="flex justify-center mt-10">
                <Button
                  link={false}
                  text="En savoir +"
                  action={() => setPopup(true)}
                  color="pink"
                  plain={true}
                  className="px-10 py-4 text-xl"
                />
              </div>
            </div>
            <div className='col-span-12 lg:col-span-6 my-auto mx-5 sm:mx-20 md:mx-0'>
              {/* <div className='flex flex-col xs:grid grid-cols-12 gap-4 mt-8'> */}
              <div className='w-56 mx-auto'>
                <div className='col-span-12 xs:col-span-6 md:col-span-3 lg:col-span-6 2xl:col-span-3'>
                  <Offer
                    className="w-full"
                    title={"Achat\nau numéro"}
                    price={"5€"}
                    btn={"Je consulte"}
                    color="orange"
                    list={[
                      [true, "Format numérique"]
                    ]}
                    action="/librairie"
                  />
                </div>
                {/* <div className='col-span-12 xs:col-span-6 md:col-span-3 lg:col-span-6 2xl:col-span-3'>
                  <Offer
                    className="w-full"
                    title={"Numérique"}
                    price={"7,50€"}
                    subscription={true}
                    color="blue"
                    list={[
                      [false, "Abonnement papier"],
                      [true, "Abonnement numérique"],
                      [true, "6 numéros par an"]
                    ]}
                    action="/abonnement/?type=numerique"
                  />
                </div>
                <div className='col-span-12 xs:col-span-6 md:col-span-3 lg:col-span-6 2xl:col-span-3'>
                  <Offer
                    className="w-full scale-100 md:scale-110 lg:scale-100 2xl:scale-110"
                    title={"Standard"}
                    price={"20,00€"}
                    subscription={true}
                    color="pink"
                    list={[
                      [true, "Abonnement papier"],
                      [true, "Abonnement numérique"],
                      [true, "6 numéros par an"]
                    ]}
                    //main={true}
                    action="/abonnement/?type=papier"
                  />
                </div>
                <div className='col-span-12 xs:col-span-6 md:col-span-3 lg:col-span-6 2xl:col-span-3'>
                  <Offer
                    className="w-full"
                    title={"Solidaire"}
                    price={"28,00€"}
                    subscription={true}
                    color="green"
                    list={[
                      [true, "Abonnement papier"],
                      [true, "Abonnement numérique"],
                      [true, "6 numéros par an"]
                    ]}
                    action="/abonnement/?type=solidaire"
                  />
                </div> */}
              </div>
            </div>
          </div>
        </section>
        <section className="mx-5 lg:mx-20 xl:mx-40 grid grid-cols-1 xl:grid-cols-2 gap-10 xl:gap-36">
          {lastIssue &&
            <div className={lastLePlus?.title ? "" : "col-span-2"}>
              <LastIssue havePlus={lastLePlus?.title ? true : false} lastIssue={lastIssue} />
            </div>
          }
          {lastLePlus &&
            <div>
              <LePlus lastLePlus={lastLePlus} />
            </div>
          }
        </section>
        <section className="w-full bg-green-bg py-14 my-20 overflow-hidden">
          <div className="mx-5 lg:mx-10 xl:mx-20 2xl:mx-40">
            <h2 className="text-5xl text-green-dark font-bold uppercase flex items-center mb-5">
              <span className="w-40 h-1 bg-green-dark hidden sm:block mr-10"></span>
              DÉCOUVRIR
            </h2>
            <div className="grid grid-cols-12 gap-6">
              <div className='col-span-12 xl:col-span-6'>
                <h3 className="text-5xl italic mb-5">Les numéros d’Animation & Éducation</h3>
                <p className="mb-5">
                  Vous êtes abonnés papier à notre revue ? Vous continuez
                  de recevoir Animation & Education dans votre école, à votre
                  domicile ou encore votre centre de documentation.
                </p>
                <p className="mb-10">
                  Vous avez manqué un numéro ? Retrouvez-le dans la librairie et
                  commandez-le en ligne.
                </p>
                <Button
                  link={true}
                  action="/librairie"
                  text="Je consulte la librairie"
                  color="blue-darker"
                  plain={false}
                  className="px-10 py-4 text-xl"
                />
              </div>
              <div className="col-span-12 xl:col-span-6 h-96 my-16 xl:my-0 xl:ml-8">
                {/* <img src="http://via.placeholder.com/640x360" /> */}
                <CarrouselIssue issues={issues} />
              </div>
            </div>




            {/* <div className="flex items-center flex-col md:flex-row">
              <div className="md:mr-5">
                <h3 className="text-5xl italic mb-5">Les numéros d’Animation & Éducation</h3>
                <p className="mb-5">
                  Vous êtes abonnés papier à notre revue ? Vous continuez
                  de recevoir Animation & Education dans votre école, à votre
                  domicile ou encore votre centre de documentation.
                </p>
                <p className="mb-10">
                  Vous avez manqué un numéro ? Retrouvez-le dans la librairie et
                  commandez-le en ligne.
                </p>
                <Button
                  link={true}
                  action="/librairie"
                  text="Je consulte la librairie"
                  color="blue-darker"
                  plain={false}
                  className="px-10 py-4 text-xl"
                />
              </div>
              <div className="w-full px-5 mt-20 md:ml-10 md:w-[60%] md:px-0 md:mt-20 h-96">
<CarrouselIssue issues={issues} />
              </div>
            </div> */}
          </div>
        </section>

        {/* ---- La force de notre Revu ---- */}
        <section className="mx-5 lg:mx-10 xl:mx-10 2xl:mx-40">
          <h2 className={`text-5xl font-bold uppercase flex items-center mb-10
            bg-clip-text text-transparent bg-gradient-to-r from-blue-darker via-blue to-blue`}>
            La force de notre revue
          </h2>

          <div className="flex flex-col xs:grid grid-cols-12 gap-4 2xl:gap-8">
            <div className="col-span-12 lg:col-span-6 xl:col-span-4 border-2 border-blue-light rounded-3xl px-4 lg:px-10 py-14 text-center">
              <Image
                className="mx-auto"
                src="imgs/picto/Picto_Redac.png"
                alt="Icone pour présenter le comité éditorial d'Animation et Éducation"
                width={160}
                height={115}
                style={{
                  maxWidth: '100%',
                  height: 'auto',
                }}
              />
              <h3 className="uppercase font-bold text-3xl my-7 break-words">
                Son comité éditorial
              </h3>
              <p className="text-left">
                Désireuse de répondre aux interrogations et besoins en ressources des enseignants dans leur pratique quotidienne de
                la classe, la revue Animation & Éducation s'appuie sur un comité éditorial composé de personnels de l'éducation :
                enseignant.es, directeurs/trices d'école, conseillers/ères pédagogiques, animateurs pédagogiques… L'implication de
                ces professionnels permet de faire émerger les défis pédagogiques auxquels sont sans cesse confrontés les enseignants
                dans le cadre de leur mission.
              </p>
            </div>
            <div className="col-span-12 lg:col-span-6 xl:col-span-4 border-2 border-blue-light rounded-3xl px-4 lg:px-10 py-14 text-center">
              <Image
                className="mx-auto"
                src="imgs/picto/Picto_Reseau.png"
                alt="Icone pour présenter les collaborateurs d'Animation et Éducation"
                width={160}
                height={115}
                style={{
                  maxWidth: '100%',
                  height: 'auto',
                }}
              />
              <h3 className="uppercase font-bold text-3xl my-7 break-words">
                Ses collaborateurs/trices nationaux régionaux et départementaux
              </h3>
              <p className="text-left">
                Sollicités pour chaque numéro de la revue, les coordonateurs/trices pédagogiques nationaux, les animateurs et
                animatrices pédagogiques des associations départementales constituent des sources de réflexion et d'information
                importantes et des relais précieux.
              </p>
            </div>
            <div className="col-span-12 lg:col-span-6 xl:col-span-4 border-2 border-blue-light rounded-3xl px-4 lg:px-10 py-14 text-center">
              <Image
                className="mx-auto"
                src="imgs/picto/Picto_Expert.png"
                alt="Icone pour présenter le réseau d'experts d'Animation et Éducation"
                width={160}
                height={115}
                style={{
                  maxWidth: '100%',
                  height: 'auto',
                }}
              />
              <h3 className="uppercase font-bold text-3xl my-7 break-words">
                Son réseau d'experts
              </h3>
              <p className="text-left">
                Dès que la thématique du dossier le nécessite, la revue fait appel aux contributions d'experts
                (de la Santé, de l'Éducation au développement durable, des Sciences humaines, des Arts, de la culture...).
                Elle sollicite, régulièrement, la contribution de sociologues, de chercheurs en sciences de l'éducation et
                de pédagogues. Elle fait appel aux membres du conseil scientifique de l'OCCE.
              </p>
            </div>
          </div>
        </section>



        {popup &&
          <Popup closeAction={() => setPopup(false)}>
            <h2 className="text-pink font-bold text-3xl mb-5">
              Revue de réflexion et d'information au service des enseignants et des formateurs
            </h2>
            <p>
              Animation & Éducation est la revue pédagogique de l'OCCE. Elle a vocation à valoriser,
              faire connaître, promouvoir les pédagogies différentes et plus particulièrement les pédagogies coopératives.
            </p>
            <p>
              Centrée à la fois sur l'apprendre à coopérer et sur le coopérer pour apprendre
              (quels que soient les apprentissages et les disciplines), elle entend donner de la visibilité
              aux actions et projets nationaux, régionaux et départementaux impulsés et développés par l'OCCE.
              Elle contribue à construire une image de l'OCCE comme mouvement pédagogique depuis 1976.
            </p>
            <p>
              S'appuyant sur un réseau de professionnels, elle propose des thématiques proches des préoccupations
              des enseignantes et enseignants dont le traitement théorico-pratique éveille et enrichit leur réflexion.
              Elle ouvre des horizons pédagogiques aux acteurs et actrices de terrain en leur proposant des apprentissages
              coopératifs, utilisables avec les adultes en formation continue ou initiale et surtout avec les élèves,
              au sein de la classe. Elle fournit ainsi des repères communs et un cadre de références pour agir en coopération scolaire.
            </p>
          </Popup>
        }
      </SWRConfig>
    </div>
  );
}

export const getServerSideProps = withAlert(async function getServerSideProps({ req, res }) {
  axiosInstance.setAxiosContext(req, res)
  setGraphContext(req, res)

  const issues = await issueSaleor.getIssues("next", null, 5, null, true);
  const lastIssue = issues?.edges?.[0]?.node ?? null;
  let lastLePlus = null;
  try {
    lastLePlus = await leplusMagazineService.getLastLePlus();
  } catch (error) {
    lastLePlus = null;
  }

  let setting = serverStore.get('setting');

  let fallback = {};
  fallback[settingService.SETTING_GET_SETTING_PATH] = setting;
  fallback[leplusMagazineService.URL_LAST_PLUS] = lastLePlus;
  return {
    props: { fallback: fallback, setting: setting, issues, lastIssue, lastLePlus: lastLePlus?.data?.[0] ?? null },
  };
})

export default WithAlert(Home)